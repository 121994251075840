import React from 'react';
import { RegionProvider } from './src/context/RegionContext';
require('./node_modules/bootstrap/dist/css/bootstrap.min.css');
require('./src/styles/global.scss');

export const wrapRootElement = ({ element }) => <RegionProvider>{element}</RegionProvider>;

export const onRouteUpdate = ({ location }) => {
  const REGION_DIMENSION = 'region';

  const prevRegion = window.localStorage.getItem('region');
  const region = window.___region || prevRegion || 'UK';

  if (window.gtag && region) {
    console.log(`Set ${region} for ${location.pathname}`);

    window.gtag('event', 'page_view', {
      page_path: location.pathname,
      [REGION_DIMENSION]: region,
    });
  }
};
